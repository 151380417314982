@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard-dynamic-subset.css");

@layer base {
    html {
        font-family: pretendard, system-ui, sans-serif;
        @apply text-dark-grey
    }

}

/* 폰트 */
.heading {
    @apply text-2xl font-pretendard text-center font-semibold -tracking-[.36px];
}
.title1 {
    @apply text-lg font-pretendard font-semibold -tracking-[.27px];
}
.title2 {
    @apply text-lg font-pretendard font-medium -tracking-[.27px];
}
.textfield {
    @apply text-[15px] font-pretendard font-medium -tracking-[.23px];
}
.caption {
    @apply text-[15px] font-pretendard text-center  -tracking-[.23px];
}


/* 기본 레이아웃 */
.wrapper{
    @apply flex flex-col grow min-h-screen w-full overflow-hidden
}
.container{
    @apply flex grow max-w-full bg-pale-grey-two
}
.content {
    @apply flex flex-col grow w-full pl-[39px] pr-[40px] py-[40px]
}
.title{
    @apply w-full text-3xl pb-3 px-6
}

/* 아이콘 */
.icon{
    @apply w-[20px] h-[20px]
}
.icon2{
    @apply w-[24px] h-[24px]
}



/* 위젯 형식 monitoring */
.boxWrapper{
    @apply flex w-full h-full
}
.box{
    @apply flex flex-col shadow-l rounded-box bg-white p-[30px]
}

.bigBox{
    @apply grow flex flex-col shadow-l rounded-box bg-white p-[40px]
}
.boxTitle{
    @apply text-lg font-pretendard font-semibold -tracking-[.27px] mb-[30px] h-[13px]
}
.boxContent{
    @apply grow rounded-b-[10px] bg-pale-grey-two
}

/* 사이드 바 (signal-result)*/
.sideBarWrapper{
    @apply hidden xl:flex flex-col justify-start items-center w-50rem h-screen bg-white border-l
}
.sideBarContainer{
    @apply w-[410px] mt-[20px]
}
.sideBarContent{
    @apply w-full
}
.sideBarSelectBox{
    @apply grow bg-arrow bg-no-repeat bg-right bg-origin-content bg-pale-grey pl-[20px] pr-[16px] textfield border-none rounded-basic h-[50px] appearance-none focus:outline-none cursor-pointer
}

.pageInput{
    @apply grow bg-pale-grey pl-[20px] pr-[16px] textfield border-none rounded-basic h-[50px] appearance-none focus:outline-none cursor-pointer
}


/* 페이지네이션 */
.pageNumBtn{
    @apply bg-inherit text-dark-grey-two cursor-pointer title2 py-1 px-3 rounded-full hover:bg-pale-grey-two transition-colors mr-[1px]
}


/* Calednar Wrapper */
.calWrapper {
    @apply flex justify-center items-center mb-[10px]
}

/* account */
.accountInput{
    @apply w-[120px] bg-white px-2 textfield border-none rounded-basic h-[40px] appearance-none focus:outline-none cursor-pointer
}

.accountSelectBox{
    @apply align-middle bg-arrow bg-no-repeat bg-right bg-origin-content pl-[20px] pr-[16px] w-[120px] bg-white textfield border-none rounded-basic h-[40px] appearance-none focus:outline-none cursor-pointer
}

.accountBtn{
    @apply flex justify-center items-center title2 rounded-basic bg-[#f0f2f4] mb-1 text-gray-400 hover:bg-[#f0f2f4] active:bg-[#f0f2f4] w-[120px] h-[42px]
}

/* loading */
.loading{
    @apply flex justify-center items-center textfield
}
